import axios from 'axios'

export default {
    namespaced: true,
    state: {
        templateDetails: {}
    },
    getters: {
        template_details(state){
            return state.templateDetails
        }
    },
    mutations: {
        setTemplateDetails(state, payload) {
            state.templateDetails = payload
        }
    },
    actions: {
        $sh_getTemplateDetails({commit}, payload) {
            axios({
                url: process.env.VUE_APP_REQ + '/requisition/template?project_id=' + payload.project_id + '&category=' + payload.category,
                method: 'get'
            }).then((res) => {
                commit('setTemplateDetails', res.data.data)
            })
        },
        $sh_createRequisition({commit}, payload) {
            return axios({
                url: process.env.VUE_APP_REQ + '/requisition',
                method: 'post',
                data: {
                    template_id: payload.template_id,
                    project_id: payload.project_id,
                    name: payload.name,
                    req_id: payload.req_id,
                    discipline: payload.discipline,
                    item: payload.item,
                    rev_number: payload.rev_number,
                    category: payload.category,
                    legacy: payload.legacy
                }
            })
        },
        $sh_uploadDocs({commit}, payload){
            return axios({
                url: process.env.VUE_APP_REQ + '/requisition/document',
                method: 'post',
                data: {
                    section_id: payload.section_id,
                    requisition_id: payload.id,
                    documents: payload.docs
                }
            })
        },
        $sh_getRequisitionDetails({commit}, payload){
            return axios({
                url: process.env.VUE_APP_REQ + '/requisition?requisition_id=' + payload,
                method: 'get'
            })
        },
        $sh_updateRequisition({commit}, payload) {
            return axios({
                url: process.env.VUE_APP_REQ + '/requisition',
                method: 'put',
                data: {
                    name: payload.name,
                    req_id: payload.req_id,
                    discipline: payload.discipline,
                    rev_number: payload.rev_number,
                    requisition_id: payload.requisition_id
                }
            })
        },
        $sh_getDocList({commit}, payload) {
            return axios({
                url: process.env.VUE_APP_REQ + '/requisition/document?requisition_id=' + payload,
                method: 'get',
            })
        },
        $sh_deleteDoc({commit}, payload) {
            return axios({
                url: process.env.VUE_APP_REQ + '/requisition/document' ,
                method: 'delete',
                data: {
                    section_id: payload.section_id,
                    requisition_id: payload.requisition_id,
                    document_name: payload.name
                }
            })
        },
        $sh_viewDoc({commit}, payload) {
            return axios({
                url: process.env.VUE_APP_REQ + '/requisition/document/view?requisition_id=' + payload.requisition_id + '&section_id=' + payload.section_id + '&document_name=' +payload.name + '&file_node_id=' + payload.file_node_id ,
                method: 'get'
            })
        }
    }
}